import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { device } from "../../submodules/logictry_common/src/utils/device";
import Constants from '../../submodules/logictry_config/constants';
import Sitemap from './sitemap.json';

const Wrapper = styled.nav`
  z-index: 1;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: white;
  > div:first-child {
    height: 40px;
    background-color: ${Constants.PrimaryColor};
    color: white;
    display: flex;
    align-items:center;
    justify-content: center;
    img:first-child {
      height: 16px;
      margin-right: 12px;
    }
    img:last-child {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;
      height: 12px;
      margin-left: 8px;
    }
    a {
      display: flex;
      align-items:center;
      justify-content: center;
    }
  }
  > div:last-child {
    margin: auto 5%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    @media ${device.laptop} {
      padding: 0.5rem 1rem;
    }
    > div:nth-child(2) {
      display: flex;
      @media ${device.laptop} {
        display: none;
      }
    }
    > div:last-child {
      display: none;
      @media ${device.laptop} {
        display: flex;
      }
    }
    > div:nth-child(2), > div:last-child {
      align-items: center;
      justify-content: flex-end;
      flex: 1;
      gap: 1.5rem;
      color: black;
      
      > a:last-child {
        > button {
          background: ${Constants.PrimaryColor};
          padding: 0.75rem 1.5rem;
          border-radius: 2rem;
          color: white;
          text-align: center;
          font-weight: 600;
        }
      }
    }
    img {
      width: 140px;
    }
  }

  > * {
    padding: 1rem;
    @media ${device.mobileL} {
      padding: 1rem 0.5rem;
    }
  }
  *:hover, *:focus, *:active {
    // color: rgba(255,255,255,0.8);
  }
  a {
    padding: 0.75rem 0;
    text-decoration: none;
    cursor: pointer;
  }

  > div > div > div {
    position: relative;
    display: inline-block;
    > div:last-child {
      display: none;
      box-shadow: 0 0 0 1px #8898aa1a, 0 15px 35px #31315d1a, 0 5px 15px #00000014;
      position: absolute;
      left: -180px;
      top: 30px;
      background-color: #f9f9f9;
      border-radius: 0.5rem;
      z-index: 1;
      padding: 2rem;
      @media ${device.laptop} {
        padding: 1rem;
      }
    }
  }
  > div > div > div:hover > div {
    display: flex;
    gap: 1.5rem;
    @media ${device.laptop} {
      display: block;
    }
  }
`;
const Links = styled.div`
  > div:first-child {
    margin: 0.5rem 0 0.5rem;
    @media ${device.laptop} {
      margin: 1rem 0 0.5rem;
    }
    font-weight: bold;
    font-size: 0.875rem;
    padding: 0 1rem;
  }
  > div:last-child {
    display: flex;
    max-height: 240px;
    @media ${device.laptop} {
      display: block;
      max-height: unset;
    }
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 220px;

    > a {
      padding: 0.5rem 1rem;
      text-decoration: none;
      display: block;
      min-width: 180px;
    }
    > a:hover {
      color: #ff5f00;
    }
  }
`;
const Seperator = styled.div`
  flex-shrink: 0;
  width: 1px;
  background: #dddddd;
`;

document.addEventListener('scroll', () => {
  if (window.scrollY > 10) document.getElementsByTagName('nav')[0].style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 0.9375rem 3.125rem 0px';
  else document.getElementsByTagName('nav')[0].style.boxShadow = '';
})

const BY_TOPIC = <Links>
  <div>BY TOPIC</div>
  <div>
    <NavLink to={Sitemap.ArtificialIntelligence}>Artificial Intelligence</NavLink>
    <NavLink to={Sitemap.InteractiveContent}>Interactive Content</NavLink>
    <NavLink to={Sitemap.NoCodePlatform}>No-Code Platform</NavLink>
    {/* <NavLink to={Sitemap.SingleSourceOfTruth}>Single Source of Truth</NavLink> */}
    {/* <NavLink to={Sitemap.StandardOperatingProcedures}>Standard Operating Procedures</NavLink> */}
  </div>
</Links>
const BY_CONTENT_TYPE = <Links>
  <div>BY CONTENT TYPE</div>
  <div>
    <NavLink to={Sitemap.Assessments}>Assessments</NavLink>
    <NavLink to={Sitemap.BuyingGuides}>Buying Guides</NavLink>
    <NavLink to={Sitemap.Calculators}>Calculators</NavLink>
    <NavLink to={Sitemap.Chatbots}>Chatbots</NavLink>
    <NavLink to={Sitemap.Collections}>Collections</NavLink>
    <NavLink to={Sitemap.DecisionTrees}>Decision Trees</NavLink>
    <NavLink to={Sitemap.Directories}>Directories</NavLink>
    <NavLink to={Sitemap.DocumentGenerators}>Document Generators</NavLink>
    <NavLink to={Sitemap.EBooks}>E-Books</NavLink>
    <NavLink to={Sitemap.ELearning}>E-Learning</NavLink>
    <NavLink to={Sitemap.EventPlanners}>Event Planners</NavLink>
    <NavLink to={Sitemap.HRHandbooks}>HR Handbooks</NavLink>
    {/* <NavLink to={Sitemap.IntakeForms}>Intake Forms</NavLink>
    <NavLink to={Sitemap.LeadQualification}>Lead Qualification</NavLink> */}
    {/* <NavLink to={Sitemap.ProductCatalogs}>Product Catalogs</NavLink> */}
    {/* <NavLink to={Sitemap.ProductManuals}>Product Manuals</NavLink> */}
    <NavLink to={Sitemap.Quizzes}>Quizzes</NavLink>
    {/* <NavLink to={Sitemap.RepairManuals}>Repair Manuals</NavLink> */}
    <NavLink to={Sitemap.SalesConfigurators}>Sales Configurators</NavLink>
    {/* <NavLink to={Sitemap.SalesGuides}>Sales Guides</NavLink> */}
    {/* <NavLink to={Sitemap.SalesPlaybooks}>Sales Playbooks</NavLink> */}
    <NavLink to={Sitemap.SmartFAQs}>Smart FAQs</NavLink>
    {/* <NavLink to={Sitemap.WhitePapers}>White Papers</NavLink> */}
  </div>
</Links>
const BY_FUNCTION = <>
  {/* <div>BY FUNCTION</div>
  <NavLink to={Sitemap.InteractiveWebsites}>Interactive Websites</NavLink>
  <NavLink to={Sitemap.KnowledgeTransfer}>Knowledge Transfer</NavLink>
  <NavLink to={Sitemap.PersonalizedLearning}>Personalized Learning</NavLink> */}
</>
const BY_DEPARTMENT = <Links>
  <div>BY DEPARTMENT</div>
  <div>
    <NavLink to={Sitemap.SalesTeams}>Sales Teams</NavLink>
    <NavLink to={Sitemap.MarketingTeams}>Marketing Teams</NavLink>
    {/* <NavLink to={Sitemap.SupportTeams}>Support Teams</NavLink> */}
    {/* <NavLink to={Sitemap.KnowledgeTeams}>Knowledge Teams</NavLink> */}
  </div>
</Links>
const BY_INDUSTRY = <Links>
  <div>BY INDUSTRY</div>
  <div>
    <NavLink to={Sitemap.Authors}>Authors</NavLink>
    <NavLink to={Sitemap.CivicOrganizations}>Civic Organizations</NavLink>
    {/* <NavLink to={Sitemap.Coaches}>Coaches</NavLink> */}
    {/* <NavLink to={Sitemap.Consultants}>Consultants</NavLink> */}
    <NavLink to={Sitemap.ECommerce}>E-Commerce</NavLink>
    <NavLink to={Sitemap.ExpertCommunities}>Expert Communities</NavLink>
    {/* <NavLink to={Sitemap.Experts}>Experts</NavLink> */}
    <NavLink to={Sitemap.LawFirms}>Law Firms</NavLink>
    {/* <NavLink to={Sitemap.LogicModellers}>Logic Modellers</NavLink> */}
  </div>
</Links>
const BY_SIZE = <>
{/* <div>BY SIZE</div>
<NavLink to={Sitemap.SmallBusiness}>Small Business</NavLink>
<NavLink to={Sitemap.Enterprise}>Enterprise</NavLink> */}
</>
const RESOURCES = <Links>
  <div>RESOURCES</div>
  <div>
    <NavLink to={Sitemap.News}>News</NavLink>
    <NavLink to={Sitemap.Articles}>Articles</NavLink>
    <NavLink to={Sitemap.CaseStudies}>Case Studies</NavLink>
    {/* <NavLink to={Sitemap.Careers}>Careers</NavLink> */}
    {/* <NavLink to={Sitemap.Videos}>Videos</NavLink> */}
    {/* <NavLink to={Sitemap.Pricing}>Pricing</NavLink> */}
  </div>
</Links>
const PARTNERS = <>
  {/* <div>PARTNERS</div>
  <NavLink to={Sitemap.ChannelPartners}>Channel Partners</NavLink>
  <NavLink to={Sitemap.ContentPartners}>Content Partners</NavLink> */}
</>
const ABOUT_US = <Links>
  <div>ABOUT US</div>
  <div>
    <NavLink to={Sitemap.Company}>About Logictry</NavLink>
    <NavLink to={Sitemap.MakeLogicPossible}>Make Logic Possible™</NavLink>
    <NavLink to={Sitemap.MakeBetterDecisionsFaster}>Make Better Decisions Faster™</NavLink>
  </div>
</Links>
const PROGRAMS = <Links>
<div>PROGRAMS</div>
<div>
  <NavLink to={Sitemap.LogicApp}>Logic App™</NavLink>
  <NavLink to={Sitemap.LogicPlatform}>Logic Platform™</NavLink>
  <NavLink to={Sitemap.WorldLogicDay}>World Logic Day</NavLink>
  {/* <NavLink to={Sitemap.WorldLogicForum}>World Logic Forum</NavLink> */}
</div>
</Links>

const DropdownSVG = <svg style={{ marginLeft: 8 }} xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" fill="none" >
  <path d="M1.20288 2.11011L5.37476 6.82498C5.41035 6.86522 5.46148 6.88825 5.51519 6.88825C5.56891 6.88825 5.62004 6.86522 5.65563 6.82498L9.82788 2.11011" stroke="black"></path>
</svg>;

const Navbar = () => (
  <Wrapper>
    <div>
      <img src="https://cdn.prod.website-files.com/66abcf3282f9eae19592ce95/66abcf3282f9eae19592cfb4_vector.png" loading="lazy" alt=""></img>
      <a href="https://logictry.com" target="_blank"><div>Try our <b><em>NEW</em> Logic App™</b></div><img src="https://cdn.prod.website-files.com/66abcf3282f9eae19592ce95/66abcf3282f9eae19592cf06_vector.svg" loading="lazy" alt=""></img></a>
    </div>
    <div>
      <div>
        <NavLink to={'/'} style={{ padding: 0, display: 'flex' }}>
          <img src="https://logictrystatic.s3.amazonaws.com/images/logo062623.svg"></img>
        </NavLink>
      </div>
      <div>
        <div>
          <NavLink to={Sitemap.FullScreenAssessment}>Can we help?</NavLink>
        </div>
        <div>
          <NavLink to="#">Products{DropdownSVG}</NavLink>
          <div style={{ width: 880, left: -400 }}>
            {BY_TOPIC}
            <Seperator />
            {BY_CONTENT_TYPE}
          </div>
        </div>
        <div>
          <NavLink to="#">Solutions{DropdownSVG}</NavLink>
          <div style={{ width: 510, left: -200 }}>
            {BY_FUNCTION}
            {/* <Seperator /> */}
            {BY_DEPARTMENT}
            <Seperator />
            {BY_INDUSTRY}
            {/* <Seperator /> */}
            {BY_SIZE}
          </div>
        </div>
        <div>
          <NavLink to="#">Company{DropdownSVG}</NavLink>
          <div style={{ width: 730, left: -500 }}>
            {RESOURCES}
            <Seperator />
            {PROGRAMS}
            <Seperator />
            {ABOUT_US}
          </div>
        </div>
        <a href="https://app.logictry.com/login" target="_blank">
          Login
        </a>
        <a href="https://app.logictry.com/register" target="_blank">
          <button>
            Register
          </button>
        </a>
      </div>
      <div>
        <div>
          <div>
            <i className="fas fa-bars"></i>
          </div>
          <div style={{ left: -236, maxHeight: 'calc(100vh - 120px)', overflow: 'auto' }}>
            <a style={{ display: 'block', padding: '0.5rem 1rem' }} href="https://app.logictry.com/login" target="_blank">
              Login
            </a>
            <a style={{ display: 'block', padding: '0.5rem 1rem' }} href="https://app.logictry.com/register" target="_blank">
              Register
            </a>
            <div style={{ display: 'block', padding: '0.5rem 1rem' }}>
              <NavLink to={Sitemap.FullScreenAssessment}>Can we help?</NavLink>
            </div>
            {BY_TOPIC}
            {BY_CONTENT_TYPE}
            {BY_FUNCTION}
            {BY_DEPARTMENT}
            {BY_INDUSTRY}
            {BY_SIZE}
            {RESOURCES}
            {PARTNERS}
            {ABOUT_US}
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
)
export default Navbar;